<template>
  <div>
    <div
      v-if="!className"
      class="d-flex justify-content-between align-items-center"
    >
      <div>
        <feather-icon
          icon="LWarningIconBlue"
          class="featherIcon"
          size="30"
        />
        {{ scanText || $t('To start the pick process, press pick or uncheck the items not to be picked.') }}
      </div>
      <div class="d-flex align-items-center inputBarCodeWrapper">
        <b-form-input
          v-if="!className"
          id="scan-input"
          v-model="scanValue"
          class="inputBarCode"
          autocomplete="off"
          placeholder="Enter barcode or sku"
          @input="fetchResults"
        />
        <b-button
          ref="scanBtn"
          class="scanBtn d-flex"
          :variant="isScanning ? 'success' : 'secondary'"
          @click.stop="toggleScanFocus"
        >
          <feather-icon
            icon="LScan"
            class="iconScan"
            size="18"
          />
          <p class="m-0">
            {{ isScanning ? 'Scanning' : 'Scan' }}
          </p>
        </b-button>
      </div>
    </div>
    <input
      id="scan-input"
      v-model="scanValue"
      :class="className ? className : 'hidden-input'"
      autocomplete="off"
      :placeholder="inputPlaceholder ? inputPlaceholder : ''"
      @input="fetchResults"
    >
    <b-modal
      id="bs-warning-modal"
      ref="bs-warning-modal"
      centered
      hide-header
      hide-footer
      modal-class="bs-warning-modal"
    >
      <b-overlay
        variant="white"
        :show="isLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <div class="text-center py-1">
          <h4 class="font-weight-bolder">
            {{ `Asset ${scanValue} incorrect` }}
          </h4>
          <div v-if="assetItem">
            <p class="pt-1">
              {{ 'You scanned:' }}
            </p>
            <p class="pt-1">
              {{ `${assetItem.product.sku} — ${assetItem.product.name}` }}
            </p>
            <p class="pt-1">
              {{ 'Please make sure you’re scanning the proper item.' }}
            </p>
          </div>
          <div v-else>
            <p class="pt-1">
              {{ 'Asset not found' }}
            </p>
          </div>
          <b-button
            variant="success"
            class="font-medium-1 font-weight-bold px-3"
            @click="hideModal"
          >
            <span>{{ 'OK' }}</span>
          </b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>

import { debounce } from '@core/utils/utils'
import * as _ from 'lodash'
import { BButton, BOverlay, BFormInput } from 'bootstrap-vue'

export default {
  name: 'Scan',
  components: { BOverlay, BButton, BFormInput },
  props: {
    assetItemSerialNumbers: {
      type: Array,
      default: () => [],
    },
    className: {
      type: String,
      default: undefined,
      required: false,
    },
    inputPlaceholder: {
      type: String,
      default: undefined,
      required: false,
    },
    scanText: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      scanValue: '',
      isLoading: false,
      assetItem: null,
      notFound: false,
      isScanning: false,
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    handleClickOutside(event) {
      if (!this.$refs.scanBtn?.contains(event.target)) {
        this.isScanning = false
      }
    },
    focusInput() {
      const inputElement = document.getElementById('scan-input')
      if (inputElement) {
        inputElement.focus({
          preventScroll: false,
        })
      }
    },

    fetchResults(value) {
      debounce(() => {
        this.scanValue = value
      }, 150)
      this.scan(this.scanValue, this)
      this.$emit('scanning', value)
    },

    scan: debounce((value, ctx) => {
      ctx.getValue(value)
    }, 800),

    getValue(value) {
      this.$emit('getValue', value)
      if (!_.find(this.assetItemSerialNumbers, s => _.includes(s, value)) && this.assetItemSerialNumbers.length) {
        this.getAssetItem(value)
      }
    },
    toggleScanFocus() {
      const scanning = !this.isScanning
      this.isScanning = scanning
      this.$emit('toggleScanFocus', scanning)
      if (scanning) {
        this.focusInput()
      }
    },
    showModal() {
      this.$bvModal.show('bs-warning-modal')
    },
    hideModal() {
      this.$bvModal.hide('bs-warning-modal')
      this.notFound = false
      this.isLoading = false
      this.assetItem = null
    },
    getAssetItem(val) {
      const searchVal = val.trim()
      this.isLoading = true
      this.showModal()

      this.$store.dispatch('warehouse-orders/getAssetByBarcode', {
        search: searchVal,
      }).then(res => {
        const { data } = res.data.data
        if (!data.length) {
          this.notFound = true
        } else {
          const findAsset = data.find(item => item.serial_number === searchVal || item.product.sku === searchVal)
          this.notFound = !findAsset
          this.assetItem = findAsset || null
        }
      }).catch(err => {
        this.errorNotification(this, err)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
<style scoped lang="scss">
.hidden-input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  width: 0;
  height: 0;
}

#bs-warning-modal {
  .modal-dialog {
    max-width: 500px !important;
  }
}

.scanBtn {
  padding: 6px;
  gap: 5px;
}

.iconScan {
  svg {
    fill: #fff
  }
}

.inputBarCodeWrapper{
  gap: 10px;
  .inputBarCode{
    height: 35px;
  }
}
</style>
