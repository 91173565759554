<template>
  <div class="form-wrapper">
    <div>
      <h3
        id="order-information-title"
        class="font-weight-bolder mb-1"
      >
        {{ $t(ORDER_INFORMATION_FORM_TITLE) }}
      </h3>
    </div>
    <b-row>
      <b-col md="6">
        <!--        <span class="font-weight-bolder pl-2 d-inline-block">{{ $t('Order Type') }}</span>-->
        <!--        <component-->
        <!--          :is="orderInformationFields[field].type"-->
        <!--          v-for="field in ['is_rental', 'is_sale']"-->
        <!--          :key="field"-->
        <!--          :ref="`${field}_ref`"-->
        <!--          v-model="orderInformation[field]"-->
        <!--          class="mb-0"-->
        <!--          v-bind="getProps(field, orderInformationFields)"-->
        <!--          @change="checkIsFilledRequiredFields"-->
        <!--        />-->
      </b-col>
      <!--      ADD MODULE_NAME_ORDERS AND CHANGE MODULE_NAME VALUE TO MODULE_NAME-->
      <b-col md="6">
        <select-account
          ref="selectAccount"
          label="Account"
          :auto-suggest-table-columns="autoSuggestTableColumnsAccount"
          :module-name="MODULE_NAME"
          :customer-i-d="customerId ? customerId : orderInformationCustomerID"
          :module-name-orders="MODULE_NAME_ORDERS"
          :default-search-query="searchQuery"
          @selectHandler="selectHandler"
          @onInput="onInput"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey, getProps } from '@core/utils/utils'
import { getUserData } from '@/auth/utils'
import config from '../config'
import SelectAccount from '../../../../../components/use-as-global/SelectAccount.vue'

export default {
  name: 'OrderInformation',
  components: {
    BRow,
    BCol,
    SelectAccount,
  },
  props: {
    customerId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      searchQuery: '',
    }
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    orderInformation() {
      return this.$store.state[this.MODULE_NAME].orderInformationForm
    },
    orderInformationCustomerID() {
      return this.orderInformation.customer_id
    },
    isOrderInformationFormRequiredFieldsFilled() {
      return this.$store.state[this.MODULE_NAME].isOrderInformationFormRequiredFieldsFilled
    },
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    paymentTermsList() {
      return this.$store.state.listModule.paymentTermList
    },
  },
  watch: {
    order(val) {
      if (val) {
        this.searchQuery = val.customer?.cust_name_dba
      }
    },
    searchQuery(val) {
      if (!val) {
        this.resetSelectedAccount()
      }
    },
  },
  created() {
    if (this.customerId) {
      this.onCustomerLoadingIfPropsHasCustomerID = true
      this.$store.dispatch(`${this.MODULE_NAME}/getCustomer`, { id: this.customerId })
        .then(({ data: { data } }) => {
          this.searchQuery = data.cust_name_dba
          this.selectHandler(data)
        })
        .finally(() => {
          this.onCustomerLoadingIfPropsHasCustomerID = false
        })
    }
  },
  methods: {
    selectHandler(item) {
      const userData = getUserData()
      const {
        id,
        customer_detail,
        default_contact, // todo: cell bn email backdan kemayabpti
        default_address,
        insurance_policy,
        customer_rules,
      } = item

      const policy_number = insurance_policy?.policy_number
      const amount_insured = insurance_policy?.amount_insured
      const deductible = insurance_policy?.deductible
      const customer_rules_filter = customer_rules?.filter(k => k.name === 'Requires Purchase Order')?.[0]?.id === 1

      this.$store.commit(`${this.MODULE_NAME}/SET_INSURANCE_INFORMATION_FORM`, {
        policy_number,
        amount_insured,
        deductible,
        coverage_end_date: new Date(insurance_policy?.coverage_end_date),
        coverage_start_date: new Date(insurance_policy?.coverage_start_date),
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, {
        ...this.customerInformation,
        billing_phone: '-',
        billing_email: '-',
        price_tier: item.customer_detail.price_tier?.label ?? '',
        price_tier_id: item.customer_detail.price_tier?.id ?? '',
        customer_notes: getValueFromGivenObjectByKey(customer_detail, 'comments', ''),
        customer_discount: customer_detail?.discount ?? 0,
        default_address,
        deposit_requirement: Number(item.deposit_requirement),
        payment_term_id: this.paymentTermsList?.[0], // we discussed that we will set the first payment term as default
        billing_address: default_address ? `${getValueFromGivenObjectByKey(default_address, 'street', '')}, ${getValueFromGivenObjectByKey(default_address, 'line2', '')}, ${getValueFromGivenObjectByKey(default_address, 'city', '')}, ${getValueFromGivenObjectByKey(default_address, 'state.code', '')} ${getValueFromGivenObjectByKey(default_address, 'zip', '')}, ${getValueFromGivenObjectByKey(default_address, 'country.three_letter', '')}` : 'Customer Missing Address',
        order_placed_by_id: userData && {
          id: userData?.id,
          full_name: `${userData?.firstname} ${userData?.lastname}`,
        },
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_PURCHASE_ORDER`, {
        requiresPurchaseOrder: customer_rules_filter,
      })
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDERS_INFORMATION_FORM`, {
        ...this.orderInformation,
        customer_id: id,
      })
      this.setAccessForAppearingForms()
    },
    onInput(searchQuery) {
      this.searchQuery = searchQuery
    },
    setAccessForAppearingForms() {
      const access = !!this.orderInformation.customer_id && this.isOrderInformationFormRequiredFieldsFilled
      this.$store.commit(`${this.MODULE_NAME}/SET_IS_SETTLED_CUSTOMER`, access)
    },
    resetSelectedAccount() {
      this.orderInformation.customer_id = null
      this.setAccessForAppearingForms()
    },
  },
  setup() {
    const {
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      orderInformationFields,
      ORDER_INFORMATION_FORM_TITLE,
      checkIsAllRequiredFieldsFilled,
      orderInformationRequiredFields,
      autoSuggestTableColumnsAccount,
    } = config()

    return {
      getProps,
      MODULE_NAME,
      MODULE_NAME_ORDERS,
      orderInformationFields,
      ORDER_INFORMATION_FORM_TITLE,
      autoSuggestTableColumnsAccount,
      orderInformationRequiredFields,
      checkIsAllRequiredFieldsFilled,
    }
  },
}
</script>
